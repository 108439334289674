import { Button, FileInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import { useNavigate } from "react-router-dom";
import { set } from "../db/firebase";
import { imageRef, metaRef } from "../db/refs";
import { CrosswordComponentProps } from "../types/app";
import { Meta } from "../types/crossword";
import { PageTitle } from "../components/page-title";

const AddImage = ({ id, meta }: CrosswordComponentProps) => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setIsValid(imageSrc.length > 0);
  }, [imageSrc]);

  const handleSaveImage = () => {
    if (!isValid) return;
    set(imageRef(id), imageSrc).then(() => navigate(`/crossword/${id}/learn/`));
  };

  return (
    <>
      <PageTitle title={`Image | ${meta.title}`} />
      <form className="grid items-center justify-center p-6 space-y-4">
        <h1 className="text-2xl font-bold text-right">{meta?.title}</h1>
        <FileInput
          accept="image/png, image/gif, image/jpeg"
          multiple={false}
          onChange={(e) => {
            const files = e.target.files;

            const fr = new FileReader();
            fr.onload = () => setImageSrc(fr.result as string);
            if (files?.length) {
              fr.readAsDataURL(files[0]);
            }
          }}
        />
        <img src={imageSrc} alt="" />
        <Button
          onClick={handleSaveImage}
          disabled={!isValid}
          style={{ direction: "rtl" }}
        >
          נראה מצויין!
        </Button>
      </form>
    </>
  );
};

export default AddImage;
