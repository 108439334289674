import React from "react";
import CellModal from "../components/crossword-cell-modal";
import CrosswordCluesDrawer from "../components/crossword-clues-drawer";
import CrosswordGrid from "../components/crossword-grid";
import CrosswordImageDrawer from "../components/crossword-image-drawer";
import CrosswordSettingsDrawer from "../components/crossword-settings-drawer";
import CrosswordSidebar from "../components/crossword-sidebar";
import CrosswordToolsDrawer from "../components/crossword-tools-drawer";
import CrosswordUsersDrawer from "../components/crossword-users-drawer";
import MobileClues from "../components/mobile-clues";
import { PageTitle } from "../components/page-title";
import { CrosswordComponentProps } from "../types/app";

function CrosswordView(props: CrosswordComponentProps) {
  const isDesktop = !('ontouchstart' in window);
  return (
    <>
      <PageTitle title={props.meta.title} />
      <CrosswordCluesDrawer {...props} />
      <CrosswordImageDrawer {...props} />
      <CrosswordUsersDrawer {...props} />
      <CrosswordSettingsDrawer {...props} />
      <CrosswordToolsDrawer {...props} />
      <CellModal {...props} />

      <div className="h-full grid grid-rows-[min-content,1fr] md:grid-rows-none md:h-full md:items-center md:max-w-[70rem]">
        <div className="flex p-1 gap-1 md:gap-4 max-w-fit">
          <CrosswordSidebar {...props} />
          <CrosswordGrid {...props} />
          {isDesktop ? <MobileClues {...props} /> : <></>}
        </div>
        {isDesktop ? <></> : <MobileClues {...props} />}
      </div>
    </>
  );
}

export default CrosswordView;
