import { Drawer, Textarea, TextInput } from "flowbite-react";
import React from 'react';
import { useObjectVal } from "react-firebase-hooks/database";
import { TbColumns } from "react-icons/tb";
import { cluesRef } from "../db/refs";
import { CrosswordComponentProps } from "../types/app";
import { Clues } from "../types/crossword";
import { ClueBoxes } from "./clue-boxes";

function CrosswordCluesDrawer(props: CrosswordComponentProps) {
    const { meta, store } = props;
    const cluesDrawerOpen = store.use.cluesDrawerOpen();

    return <Drawer position={('ontouchstart' in window) ? "top": "left"} className="grid grid-rows-[max-content,1fr] md:w-1/3" open={cluesDrawerOpen}
                   onClose={() => store.set.cluesDrawerOpen(false)}>
        <Drawer.Header titleIcon={TbColumns} title={`${meta.title} | ${meta.author}`} />
        <Drawer.Items>
            <ClueBoxes {...props} />
        </Drawer.Items>
    </Drawer>;
}

export default CrosswordCluesDrawer;
