import { Button, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { push, serverTimestamp } from "../db/firebase";
import { cwListRef as cwList } from "../db/refs";
import { PageTitle } from "../components/page-title";

const NewCrossword = () => {
    const navigate = useNavigate();
    const cwListRef = cwList();
    const [title, setTitle] = useState<string>("");
    const [author, setAuthor] = useState<string>("");
    const [rows, setRows] = useState<string>("11");
    const [cols, setCols] = useState<string>("11");
    const [isValid, setIsValid] = useState<boolean>(false);
    const [username, setUsername] = useState<string>(localStorage.getItem('username') ?? "");

    useEffect(() => {
        const username: string = localStorage.getItem('username') ?? "";

        while (username.length < 3) {
            setUsername(prompt("אז, איך קוראים לנו?") ?? "");
            localStorage.setItem('username', username);
        }
    }, [username]);

    useEffect(() => {
        setIsValid(title.length > 0 && author.length > 0 && parseInt(rows) > 0 && parseInt(cols) > 0);
    }, [title, author, rows, cols]);

    const handleCreateCrossword = () => {
        if (!isValid) return;

        const newCrosswordData = {
            data: Array.from({ length: parseInt(rows) }, () => Array(parseInt(cols)).fill({ v: "" })),
            meta: {
                title,
                author,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                updatedBy: username
            }
        };
        setIsValid(false);
        push(cwListRef, newCrosswordData).then(({ key }) => navigate(`/crossword/${key}/add-image/`));
    };

    return (
      <>
        <PageTitle title={`תשבץ חדש`} />
        <form
          className="flex flex-col items-center justify-center p-6 space-y-4"
          style={{ direction: "rtl" }}
        >
          <h1 className="text-2xl font-bold">תשבץ חדש</h1>
          <div className="space-y-2 w-full max-w-md">
            <label htmlFor="title">כותרת:</label>
            <TextInput
              id="title"
              placeholder="תרתי משמע מס' 1337"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <label htmlFor="author">מחבר:</label>
            <TextInput
              id="author"
              placeholder="אמנון הרועה"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />

            <label htmlFor="rows">שורות:</label>
            <TextInput
              id="rows"
              value={rows}
              onChange={(e) => setRows(e.target.value)}
              type="number"
              min="1"
            />

            <label htmlFor="cols">עמודות:</label>
            <TextInput
              id="cols"
              value={cols}
              onChange={(e) => setCols(e.target.value)}
              type="number"
              min="1"
            />
          </div>

          <Button onClick={handleCreateCrossword} disabled={!isValid}>
            שנעלה לזה תמונה?
          </Button>
        </form>
      </>
    );
};

export default NewCrossword;
