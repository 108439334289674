import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import OCRComponent from "./components/ocr";
import "./index.css";
import AddImage from "./pages/add-image";
import CrosswordView from "./pages/crossword-view";
import CrosswordWrapper from "./pages/crossword-wrapper";
import Home from "./pages/home";
import New from "./pages/new";
import reportWebVitals from "./reportWebVitals";

const router = createHashRouter([
    { path: "/", element: <Home/> },
    { path: "new", element: <New/> },
    { path: "crossword/:id", element: <CrosswordWrapper ChildComponent={CrosswordView}/> },
    { path: "crossword/:id/add-image", element: <CrosswordWrapper ChildComponent={AddImage}/> },
    { path: "crossword/:id/learn", element: <CrosswordWrapper ChildComponent={OCRComponent}/> },
    { path: "crossword/:id/edit", element: <Home/> },
]);

const GlobalWrapper = ({ children }: PropsWithChildren) => {
    return <div className="font-sans h-dvh overflow-y-scroll md:grid md:place-items-center">{children}</div>;
};

const rootElement = document.getElementById("root");
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <GlobalWrapper>
                <RouterProvider router={router}/>
            </GlobalWrapper>
        </React.StrictMode>,
    );

    reportWebVitals();
}
