import { Checkbox, Drawer, Label } from "flowbite-react";
import React from 'react';
import { GoGear } from "react-icons/go";
import { CrosswordComponentProps } from "../types/app";

function CwSetCheck({ label, checked, onChange }: {
    label: string,
    checked: boolean,
    onChange: (checked: boolean) => void
}) {
    const id = (Math.random() + 1).toString(36).substring(7);
    return <div className="grid items-center gap-2 grid-cols-[1fr,max-content]">
        <Label htmlFor={id} className="text-right text-md" style={{ direction: "rtl" }}>
            {label}
        </Label>
        <Checkbox id={id} checked={checked} onChange={e => onChange(e.target.checked)}/>
    </div>;
}

function CrosswordSettingsDrawer({ store }: CrosswordComponentProps) {
    const settingsDrawerOpen = store.use.settingsDrawerOpen();
    const showUserColors = store.use.showUserColors();
    const showOthers = store.use.showOthers();
    const lineThrough = store.use.lineThrough();
    const blackEdit = store.use.blackEdit();

    return <Drawer position="top" className="grid grid-rows-[max-content,1fr]" open={settingsDrawerOpen}
                   onClose={() => store.set.settingsDrawerOpen(false)}>
        <Drawer.Header title="דברים של הייטרים" titleIcon={GoGear}/>
        <Drawer.Items className="grid px-4 gap-1">
            <CwSetCheck label="אל תציג צבעי משתמשים" checked={!showUserColors}
                        onChange={checked => store.set.showUserColors(!checked)}/>
            <CwSetCheck label="אל תראה לי איפה אחרים" checked={!showOthers}
                        onChange={checked => store.set.showOthers(!checked)}/>
            <CwSetCheck label="למחוק הגדרות פתורות עם קו!" checked={lineThrough}
                        onChange={checked => store.set.lineThrough(checked)}/>
            <CwSetCheck label="לערוך שחורים בידיים?" checked={blackEdit}
                        onChange={checked => store.set.blackEdit(checked)}/>
        </Drawer.Items>
    </Drawer>
        ;

}

export default CrosswordSettingsDrawer;
