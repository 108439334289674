
export const seedColor = (str) => {
    // Define the number of base colors and fixed saturation and lightness
    const numberOfBaseColors = 36; // Adjust this number for more or fewer base hues
    const saturation = 70; // Fixed saturation for vivid colors
    const lightness = 50; // Fixed lightness for visibility against white
    const hueIncrement = 360 / numberOfBaseColors; // Dividing the color wheel into segments

    // Simple hashing function to pick a hue index
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colorIndex = Math.abs(hash % numberOfBaseColors);

    // Calculate hue directly based on the color index
    let hue = (colorIndex * hueIncrement) % 360;

    // Modify hue slightly to add uniqueness
    let hueShift = (hash % 35) - 17; // Shift hue by -17 to +17 degrees
    hue = (hue + hueShift + 360) % 360;

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};
