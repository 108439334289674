import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useObjectVal } from "react-firebase-hooks/database"; /** eslint-disable react/jsx-no-undef */
import { HiArrowCircleDown, HiArrowCircleLeft } from "react-icons/hi";
import { VscDash } from "react-icons/vsc";
import { cluesRef } from "../db/refs";
import { CrosswordComponentProps } from "../types/app";
import { Cell, Clues, Position } from "../types/crossword";
import { Direction } from "../types/enums";
import { seek } from "../utils/cell-utils";

function MobileClues({ id, data, store }: CrosswordComponentProps) {
  const { rowIdx, cellIdx } = store.use.focusPosition();
  const [clues /*, loading, error*/] = useObjectVal<Clues>(cluesRef(id ?? ""));
  const [hCell, setHCell /*, loading, error*/] = useState<Cell | null>(null);
  const [vCell, setVCell /*, loading, error*/] = useState<Cell | null>(null);
  const [hCellSolved, setHCellSolved /*, loading, error*/] = useState<boolean>(false);
  const [vCellSolved, setVCellSolved /*, loading, error*/] = useState<boolean>(false);
  const lineThrough = store.use.lineThrough();


  useEffect(() => {
    const h = seek(
      data,
      Direction.Right,
      rowIdx,
      cellIdx,
      {
        seekFn: (cell: Cell) => Number.isInteger(cell.index?.hIndex),
        stopFn: (cell: Cell, target: Position) => target.rowIdx !== rowIdx,
        callback: () => {},
      },
      true,
    );
    const v = seek(
      data,
      Direction.Up,
      rowIdx,
      cellIdx,
      {
        seekFn: (cell: Cell) => {
          return Number.isInteger(cell.index?.vIndex);
        },
        //stopFn: (cell: Cell, target:Position) => target.rowIdx === rowIdx,
        callback: () => {},
      },
      true,
    );
    setHCell(h?.cell ?? null);
    setVCell(v?.cell ?? null);

    if (h) {
      const hSolved = seek(
        data,
        Direction.Left,
        h.target.rowIdx,
        h.target.cellIdx,
        {
          seekFn: (cell: Cell, target) => cell.black || (target.cellIdx === data[0].length - 1 && Boolean(cell?.v?.length)),
          stopFn: (cell: Cell) => !cell?.v?.length,
          callback: () => {},
        },
        true,
      );
      setHCellSolved(Boolean(hSolved));
    } else {
      setHCellSolved(true);
    }

    if (v) {
      const vSolved = seek(
        data,
        Direction.Down,
        v.target.rowIdx,
        v.target.cellIdx,
        {
          seekFn: (cell: Cell, target) => cell.black || (target.rowIdx === data.length - 1 && Boolean(cell?.v?.length)),
          stopFn: (cell: Cell) => !cell?.v?.length,
          callback: () => {},
        },
        true,
      );
      setVCellSolved(Boolean(vSolved));
    } else {
      setVCellSolved(true);
    }
  }, [rowIdx, cellIdx]);

  const upCell = data[rowIdx - 1]?.[cellIdx];
  const downCell = data[rowIdx + 1]?.[cellIdx];

  const rightCell = data[rowIdx]?.[cellIdx - 1];
  const leftCell = data[rowIdx]?.[cellIdx + 1];

  const hasH = (hCell && leftCell && !leftCell?.black) || (rightCell && !rightCell?.black);
  const hasV = (vCell && upCell && !upCell?.black) || (downCell && !downCell?.black);

  const hClue = hasH ? clues?.horizontal[hCell?.index?.hIndex ?? 100] ?? "" : "";
  const vClue = hasV ? clues?.vertical[vCell?.index?.vIndex ?? 100] ?? "" : "";

  return (
    <div
      className="bg-teal-600 grid h-full grid-cols-[min-content,min-content,1fr] items-start grid-rows-[min-content,min-content,1fr] text-white text-right text-lg p-2 leading-none gap-2 md:text-2xl md:items-center md:h-auto md:min-w-96"
      style={{ direction: "rtl" }}
    >
      <HiArrowCircleLeft />
      <div
        className={clsx(
          "font-semibold text-center h-full",
          hCellSolved && "text-slate-400"
        )}
      >
        {hCell?.index?.number ? `${hCell?.index?.number}. ` : "- "}
      </div>
      <div
        className={clsx(
          hCellSolved && `${lineThrough ? "line-through" : ""} text-slate-400`
        )}
      >
        {hCell?.index?.number ? hClue : ""}
      </div>

      <HiArrowCircleDown />
      <div
        className={clsx(
          "font-semibold text-center h-full",
          vCellSolved && "text-slate-400"
        )}
      >
        {vCell?.index?.number ? `${vCell?.index?.number}. ` : "- "}
      </div>
      <div
        className={clsx(
          vCellSolved && `${lineThrough ? "line-through" : ""} text-slate-400`
        )}
      >
        {vCell?.index?.number ? vClue : ""}
      </div>
    </div>
  );
}

export default MobileClues;
