import { Button, Textarea } from "flowbite-react";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useObjectVal } from "react-firebase-hooks/database";
import { Image, Layer, Rect, Stage, Transformer } from 'react-konva';
import Tesseract, { ImageLike, RecognizeResult } from 'tesseract.js';
import useImage from 'use-image';
import { database, onValue, ref, set } from "../db/firebase";
import { cellBlackRef, cluesHorizontalRef, cluesRef, cluesVerticalRef, dataRef } from "../db/refs";
import { CrosswordComponentProps } from "../types/app";
import { Cell, Cells, Clues } from "../types/crossword";
import CrosswordGrid from "./crossword-grid";
import { PageTitle } from "../components/page-title";
import Loader from "./loader";
import { ClueBoxes } from "./clue-boxes";

enum RegionTypes {
  Text = "text",
  Grid = "grid",
}

type Region = {
  x: number;
  y: number;
  width: number;
  height: number;
  id: string;
  ref: RefObject<Konva.Rect>;
  type: RegionTypes;
};

const OCRComponent = (props: CrosswordComponentProps) => {
  const { id, data, meta } = props;
  const [horizontalCluesData] = useObjectVal(
    cluesHorizontalRef(id)
  ) as unknown as [string[]];
  const [verticalCluesData] = useObjectVal(cluesVerticalRef(id)) as unknown as [
    string[],
  ];
  // const [horizontalClues, setHorizontalClues] = useState<string[]>([]);
  // const [verticalClues, setVerticalClues] = useState<string[]>([]);

  const hRef = useRef<HTMLTextAreaElement>(null);
  const vRef = useRef<HTMLTextAreaElement>(null);

  const [rectangles, setRectangles] = useState<Region[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [statusText, setStatusText] = useState("");
  const [imageData, setImageData] = useState("");
  const [image] = useImage(imageData, "anonymous");
  const transformerRef: RefObject<Konva.Transformer> = useRef(null);
  const [gridSize] = useState({ rows: data.length, cols: data[0].length }); // For grid size
  const [cellData, setCellData] = useState<Cells>([]); // For grid size
  const [scale, setScale] = useState(1); // Scale factor

  useEffect(() => {
    let unsubscribe: () => void; // Declare unsubscribe outside the callback
    // eslint-disable-next-line prefer-const
    unsubscribe = onValue(dataRef(id), (snap) => {
      setCellData(snap.val());
      setTimeout(() => unsubscribe());
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const dbRef = ref(database, `/images/${id}/image`);
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        setImageData(snapshot.val());
      } else {
        console.log("No image available");
      }
    });

    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    if (selectedId) {
      const transformer = transformerRef.current;
      const selectedRect = rectangles.find((r) => r.id === selectedId);
      const shapes: Konva.Rect[] = selectedRect?.ref?.current
        ? [selectedRect.ref.current]
        : [];
      transformer?.setNodes(shapes);
      transformer?.getLayer()?.batchDraw();
    }
  }, [selectedId, rectangles]);

  useEffect(() => {
    if (image) {
      const scaleFactor = calculateScaleFactor(image.width, image.height);
      setScale(scaleFactor);
    }
  }, [image]);

  const calculateScaleFactor = (
    imageWidth: number,
    imageHeight: number
  ): number => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    return Math.min(screenWidth / imageWidth, screenHeight / imageHeight) * 0.9;
  };

  const handleRectChange = (newAttrs: Partial<Region>, index: number) => {
    const rects = rectangles.slice();
    rects[index] = { ...rects[index], ...newAttrs };
    setRectangles(rects);
  };

  const handleDragEnd = (index: number) => (event: KonvaEventObject<Event>) => {
    handleRectChange(
      {
        x: event.target.x() / scale,
        y: event.target.y() / scale,
      },
      index
    );
  };

  const handleTransformEnd =
    (index: number) => (event: KonvaEventObject<Event>) => {
      const node = event.target;
      if (!node) return;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      node.scaleX(1);
      node.scaleY(1);
      handleRectChange(
        {
          x: node.x() / scale,
          y: node.y() / scale,
          width: Math.max(5, (node.width() * scaleX) / scale),
          height: Math.max(5, (node.height() * scaleY) / scale),
        },
        index
      );
    };

  const addRegion = (type = RegionTypes.Text) => {
    if (!image) return;
    const isText = type === RegionTypes.Text;
    const newId = (rectangles.length + 1).toString();
    const width =
      (isText ? image.width * 0.33 : image.width * 0.55) / Math.max(scale, 1);
    const height =
      (isText ? image.height * 0.3 : image.width * 0.55) / Math.max(scale, 1);
    setRectangles(
      rectangles.concat([
        {
          x: (image.width / 2 - width / 2) / scale,
          y: (image.height / 2 - height / 2) / scale,
          width,
          height,
          id: `${type}-${newId}`,
          ref: React.createRef(),
          type,
        },
      ])
    );
    setSelectedId(newId);
  };

  const imageDataToImage = (imageData: ImageData) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    ctx.putImageData(imageData, 0, 0);

    return canvas.toDataURL();
  };

  // Function to convert image data to grayscale
  function convertToGrayscale(imageData: ImageData) {
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const avg = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2]; // weighted average for human perception
      data[i] = avg; // red channel
      data[i + 1] = avg; // green channel
      data[i + 2] = avg; // blue channel
      // alpha channel remains unchanged
    }
    return imageData;
  }

  // Simple sharpen function for demonstration
  const applySharpen = (imageData: ImageData) => {
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      data[i] = data[i] * 1.1; // Increase R channel slightly
      data[i + 1] = data[i + 1] * 1.1; // Increase G channel slightly
      data[i + 2] = data[i + 2] * 1.1; // Increase B channel slightly
    }
    return imageData;
  };

  // Simple contrast enhancement function for demonstration
  const adjustContrast = (imageData: ImageData, contrast: number) => {
    const data = imageData.data;
    const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));

    for (let i = 0; i < data.length; i += 4) {
      data[i] = factor * (data[i] - 128) + 128; // Adjust R channel
      data[i + 1] = factor * (data[i + 1] - 128) + 128; // Adjust G channel
      data[i + 2] = factor * (data[i + 2] - 128) + 128; // Adjust B channel
    }
    return imageData;
  };

  function levenshteinDistance(s1: string, s2: string) {
    const len1 = s1.length;
    const len2 = s2.length;
    const matrix = [];

    // Initialize the matrix
    for (let i = 0; i <= len1; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= len2; j++) {
      matrix[0][j] = j;
    }

    // Calculate distances
    for (let i = 1; i <= len1; i++) {
      for (let j = 1; j <= len2; j++) {
        const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1, // Deletion
          matrix[i][j - 1] + 1, // Insertion
          matrix[i - 1][j - 1] + cost // Substitution
        );
      }
    }

    return matrix[len1][len2];
  }

  const parseClues = async () => {
    setStatusText("LOADING");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    if (!image) return;
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the original image onto the canvas
    ctx.drawImage(image, 0, 0, image.width, image.height);

    // Retrieve the full image data from the canvas
    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    // Apply image processing
    imageData = convertToGrayscale(imageData);
    //imageData = applySharpen(imageData);
    imageData = adjustContrast(imageData, 80);

    // Put the processed image data back to canvas to check the effect visually
    ctx.putImageData(imageData, 0, 0);

    // Debug: display the canvas in the document to see the changes
    // document.body.appendChild(canvas); // This line helps to see the processed image

    //let counter = 0;
    const results: RecognizeResult[] = [];
    for (const rect of rectangles.filter(({ type }) => type === "text")) {
      const rectImageData: ImageData = ctx.getImageData(
        rect.x * scale,
        rect.y * scale,
        rect.width * scale,
        rect.height * scale
      );
      results.push(
        await Tesseract.recognize(
          imageDataToImage(rectImageData) as ImageLike,
          "heb"
        )
      );
    }

    const strings: string = results
      .map((r) => r.data.text)
      .join(" ")
      .replaceAll("\n", " ")
      .replace(" מאונך:", "\nמאונך:")
      .replace(/(\s+\d\.)/g, (match) => match.replace(/\s+/g, "\n"));

    const clues: Clues = {
      horizontal: [],
      vertical: [],
    };
    let target = clues.horizontal;
    strings.split("\n").forEach((line: string) => {
      const similarityThreshold = 1;
      const distanceV = levenshteinDistance(line, "מאוזן:");
      if (distanceV <= similarityThreshold) return (target = clues.horizontal);
      const distanceH = levenshteinDistance(line, "מאונך:");
      if (distanceH <= similarityThreshold) return (target = clues.vertical);
      target.push(
        line
          .replace(/.*\. +/, "")
          .replace(/ +/g, " ")
          .replaceAll("עַ", "ע")
      );
    });
    await set(cluesRef(id), clues);
    //setOutputText(JSON.stringify(clues, null, 2));
  };

  const parseGrid = () => {
    const gridOverlay = rectangles.filter(({ type }) => type === "grid")[0];
    if (!gridOverlay) return;
    const { x, y, width, height } = gridOverlay;
    const cellWidth = (width * scale) / gridSize.cols;
    const cellHeight = (height * scale) / gridSize.rows;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    if (!image) return;

    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the original image onto the canvas
    ctx.drawImage(image, 0, 0, image.width, image.height);

    // Retrieve the full image data from the canvas
    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    // Apply image processing
    imageData = convertToGrayscale(imageData);
    imageData = applySharpen(imageData);
    imageData = adjustContrast(imageData, 50);
    imageData = applySharpen(imageData);
    imageData = adjustContrast(imageData, 50);

    // Put the processed image data back to canvas to check the effect visually
    ctx.putImageData(imageData, 0, 0);

    const newCellData: Cells = [];

    for (let row = 0; row < gridSize.rows; row++) {
      const rowData: Cell[] = [];
      for (let col = 0; col < gridSize.cols; col++) {
        const cx = x * scale + col * cellWidth + cellWidth / 2;
        const cy = y * scale + row * cellHeight + cellHeight / 2;
        const sampleSize = Math.round(cellWidth * 0.4);
        const imageData = ctx.getImageData(
          cx - sampleSize / 2,
          cy - sampleSize / 2,
          sampleSize,
          sampleSize
        );

        const avgColor =
          imageData.data.reduce((acc, val, idx) => {
            if (idx % 4 !== 3) acc += val;
            return acc;
          }, 0) /
          (sampleSize * sampleSize * 3);

        rowData.push({ black: avgColor < 128 });
      }
      newCellData.push(rowData.reverse());
    }

    setCellData(newCellData);
    setStatusText("זה המבנה שיצא לנו.");
  };

  if (!image) {
    return <div>LOADING</div>;
  }

  return (
    <>
      <PageTitle title={`OCR | ${meta.title}`} />
      <div className="grid md:grid-flow-col">
        <div className="grid justify-items-center grid-rows-[min-content,max-content]">
          <div className="grid grid-flow-col place-items-center p-2 gap-1">
            <div className="grid grid-flow-col place-items-center p-2 gap-1">
              <Button
                size="xs"
                onClick={() => addRegion(RegionTypes.Text)}
                className="bg-blue-500 text-white rounded"
              >
                Add Region
              </Button>
              <Button
                size="xs"
                onClick={parseClues}
                className="bg-blue-500 text-white rounded"
              >
                Parse Text
              </Button>
            </div>
            <div className="grid grid-flow-col place-items-center p-2 gap-1">
              <Button
                size="xs"
                onClick={() => addRegion(RegionTypes.Grid)}
                className="bg-green-500 text-white rounded"
              >
                Add Grid
              </Button>
              <Button
                size="xs"
                onClick={parseGrid}
                className="bg-green-500 text-white rounded"
              >
                Parse Grid
              </Button>
            </div>
          </div>
          <Stage
            width={image.width * scale}
            height={image.height * scale}
            scaleX={scale}
            scaleY={scale}
            onMouseDown={(e) => {
              const clickedOnEmpty = e.target === e.target.getStage();
              if (clickedOnEmpty) {
                setSelectedId(null);
              }
            }}
          >
            <Layer>
              <Image image={image} />
              {rectangles.map((rect, i) => (
                <Rect
                  key={i}
                  x={rect.x * scale}
                  y={rect.y * scale}
                  width={rect.width * scale}
                  height={rect.height * scale}
                  draggable
                  onMouseDown={() => setSelectedId(rect.id)}
                  onTap={() => setSelectedId(rect.id)}
                  onDragEnd={() => {
                    handleDragEnd(i);
                    setSelectedId(rect.id);
                  }}
                  onTransformEnd={handleTransformEnd(i)}
                  ref={rect.ref}
                  stroke={rect.type === "text" ? "blue" : "red"}
                  strokeWidth={6}
                  fill="rgba(0, 0, 255, 0.1)"
                />
              ))}

              {selectedId && (
                <Transformer
                  ref={transformerRef}
                  keepRatio={false}
                  rotateEnabled={false}
                />
              )}
            </Layer>
          </Stage>
        </div>
        <div className="grid grid-flow-row p-8">
          <div className="grid md:grid-flow-col gap-8 place-items-center">
            <div className="w-64 pointer-events-none">
              {cellData.length ? (
                <CrosswordGrid {...{ ...props, data: cellData }} />
              ) : (
                <></>
              )}
            </div>
            <div className="grid grid-rows-[min-content,max-content] place-content-end">
              <div className="grid grid-flow-col gap-2">
                <a href={`/#/crossword/${id}`}>
                  <Button
                    size="xs"
                    className="bg-amber-700 text-white rounded grid"
                    style={{ direction: "rtl" }}
                  >כשסיימנו פה, סיימנו פה!<br />(לא לשכוח לשמור למטה)</Button>
                </a>
                <Button
                  size="xs"
                  className="bg-green-500 text-white rounded"
                  style={{ direction: "rtl" }}
                  onClick={() => {
                    (cellData ?? []).forEach((row, rowIdx) => {
                      row.forEach((cell, cellIdx) => {
                        if (cell.black)
                          void set(cellBlackRef(id, rowIdx, cellIdx), true);
                      });
                    });
                  }}
                >
                  נשמור את המבנה?
                </Button>
              </div>
            </div>
          </div>
          <div>
            <ClueBoxes {...props} />
          </div>
        </div>
      </div>
    </>
  );
};

export default OCRComponent;
