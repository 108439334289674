import { remove } from "firebase/database";
import React, { useEffect, useMemo } from 'react';
import { useObjectVal } from "react-firebase-hooks/database"; // Define ChildComponent as a React functional component that accepts ChildComponentProps
import { useParams } from 'react-router-dom';
import Loader from '../components/loader';
import { onDisconnect, onValue, push, serverTimestamp, set } from "../db/firebase";
import {
    connectedRef,
    dataRef,
    lastOnlineRef,
    metaRef,
    myConnectionsRef,
    positionsRef,
    userPositionRef
} from "../db/refs";
import { createCrosswordStore } from "../state/crossword-store";
import { CrosswordComponentProps } from "../types/app";
import { Cells, Meta, Position } from "../types/crossword";

// Define ChildComponent as a React functional component that accepts ChildComponentProps
interface CrosswordWrapperProps {
    ChildComponent: React.FC<CrosswordComponentProps>;
}

function CrosswordWrapper({ ChildComponent }: CrosswordWrapperProps) {
    const { id } = useParams<{ id: string }>();
    const store = useMemo(() => createCrosswordStore({}), [id]);
    const username = store.use.username();
    const focusPosition = store.use.focusPosition();
    const [meta/*, loading, error*/] = useObjectVal<Meta>(metaRef(id ?? ''));
    const [data/*, loading, error*/] = useObjectVal<Cells>(dataRef(id ?? ''));
    const [positions/*, loading, error*/] = useObjectVal<{ [key: string]: Position }>(positionsRef(id ?? '')) ?? {};

    useEffect(() => {
        if (!username) return;
        const unsubscribe = onValue(connectedRef(), (snap) => {
            if (snap.val() === true) {
                // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
                const con = push(myConnectionsRef(username));

                // When I disconnect, remove this device
                void onDisconnect(con).remove();

                // Add this device to my connections list
                // this value could contain info about the device or a timestamp too
                set(con, true);

                // When I disconnect, update the last time I was seen online
                const lastOnline = lastOnlineRef(username);
                void onDisconnect(lastOnline).set(serverTimestamp());
                return () => {
                    void remove(con);
                    void set(lastOnline, serverTimestamp());
                    unsubscribe();
                };
            }
        });
    }, [username]);

    useEffect(() => {
        if (!id || !username || !focusPosition) return;
        const userPosition = userPositionRef(id, username);
        void set(userPosition, focusPosition);
        void onDisconnect(userPosition).remove();
        return () => {
            void remove(userPosition);
        };
    }, [focusPosition.rowIdx, focusPosition.cellIdx, id, username]);

    useEffect(() => {
        if(!positions) return;
        const result: string[][][] = [];

        Object.keys(positions).forEach(user => {
            const { cellIdx, rowIdx } = positions[user];
            result[rowIdx] ??= [];
            result[rowIdx][cellIdx] ??= [];
            result[rowIdx][cellIdx].push(user);
        });

        store.set.cellUsers(result);
    }, [positions]);

    if (!id || !meta || !data) return <></>;
    const props: CrosswordComponentProps = { id, meta, data, store };

    // Check if meta is available and if the id is defined
    return meta && id ? <ChildComponent {...props}/> : <Loader text="הנה זה בא"/>;
}

export default CrosswordWrapper;
