import { Button, Drawer, Label } from "flowbite-react";
import React, { useEffect, useState } from 'react';
import { useObjectVal } from "react-firebase-hooks/database";
import { FaUserTag } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa6";
import { TfiTarget } from "react-icons/tfi";
import { positionsRef } from "../db/refs";
import { CrosswordComponentProps } from "../types/app";
import { Position } from "../types/crossword";
import { setFocus } from "../utils/cell-utils";
import { seedColor } from "../utils/seed-color";
import Loader from "./loader";
import clsx from "clsx";

function CwUserRow({ username, position, close }: { username: string, position?: Position, close: () => void }) {
    return <div className="grid items-center gap-2 grid-cols-[max-content,1fr,max-content]">
        <Button size="xs" className={clsx("aspect-1 items-center", !position && "invisible")} theme={{ size: { sm: "px-1.5 text-sm" } }}
                onClick={() => {
                    setFocus(position ?? {rowIdx: 1, cellIdx: 1});
                    close();
                }}>
            <TfiTarget/>
        </Button>
        <Label htmlFor="accept" className="text-right text-md font-normal"
               style={{ direction: "rtl", color: seedColor(username) }}>
            {username}
        </Label>
        <FaUserGraduate className={clsx(!position && "text-gray-400")}/>
    </div>;
}

function CrosswordUsersDrawer({ id, meta, store }: CrosswordComponentProps) {
    const [positions, loading] = useObjectVal<{ [key: string]: Position }>(positionsRef(id));
    const [solvers, setSolvers] = useState<{ [key: string]: Position | null }>({});
    const usersDrawerOpen = store.use.usersDrawerOpen();
    
    useEffect(() => {
        const updated = Object.keys(meta.solvers ?? {}).reduce((acc, key) => {
            acc[key] = null;
            return acc;
          }, {} as { [key: string]: Position | null });
        setSolvers({...updated, ...positions});
    }, [positions, meta.solvers]);

    return <Drawer position="top" className="grid grid-rows-[max-content,1fr]" open={usersDrawerOpen}
                   onClose={() => store.set.usersDrawerOpen(false)}>
        <Drawer.Header titleIcon={FaUserTag}></Drawer.Header>
        {loading ? <Loader/> : <div className="grid px-4 gap-1">
            {Object.entries(solvers ?? []).map(([username, position]) => (
                <CwUserRow key={username} username={username} position={position as Position}
                           close={() => store.set.usersDrawerOpen(false)}/>))}
        </div>}
    </Drawer>;
}

export default CrosswordUsersDrawer;
