import React, { useRef, useState } from "react";
import { CrosswordComponentProps } from "../types/app";
import { Clues } from "../types/crossword";
import { Button, Textarea } from "flowbite-react";
import Loader from "./loader";
import { IndexTypes } from "../types/enums";
import { set } from "../db/firebase";
import { cluesHorizontalRef, cluesRef, cluesVerticalRef } from "../db/refs";
import { useObjectVal } from "react-firebase-hooks/database";


const ClueBoxes = ({ id, data, readOnly = false, onChange = () => {}, onSave = () => {} }: CrosswordComponentProps & {readOnly?: boolean, onChange?: () => void, onSave?: () => void}) => {
  const hRef = useRef<HTMLTextAreaElement>(null);
  const vRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [clues, cluesLoading] = useObjectVal<Clues>(cluesRef(id));
  
  // @ts-ignore
  const hClues = data?.flatMap(row => row.flatMap(cell => [IndexTypes.Both, IndexTypes.Horizontal].includes(cell?.index?.type) ? cell?.index?.number: undefined)).filter(i => !!i);
  // @ts-ignore
  const vClues = data?.flatMap(row => row.flatMap(cell => [IndexTypes.Both, IndexTypes.Vertical].includes(cell?.index?.type) ? cell?.index?.number: undefined)).filter(i => !!i);


  function saveClues() {
    setLoading(true);
    const hClues = hRef?.current?.value;
    const vClues = vRef?.current?.value;

    Promise.all([
        hClues !== hRef?.current?.defaultValue && set(cluesHorizontalRef(id), (hClues ?? "").split("\n")),
        vClues !== vRef?.current?.defaultValue && set(cluesVerticalRef(id), (vClues ?? "").split("\n"))
    ]).finally(async () => {
      await onSave();
      setLoading(false);
    });
  }

  return clues ? (
      <div className="grid gap-2 text-right" style={{ direction: "rtl" }}>
      <p className="font-semibold">מאוזן:</p>
      <div className="group rtl grid grid-cols-[2.4rem,1fr]">
      <Textarea readOnly defaultValue={hClues.join('.\n') + "."}  rows={hClues.length}
              color={clues.horizontal.length !== hClues.length ? "failure" : "gray"}
              className="min-h-64 w-full p-2 resize-none rounded-l-none border-l-0 pointer-events-none group-focus-within:border-cyan-500"/>
      <Textarea readOnly={readOnly} ref={hRef} defaultValue={clues.horizontal.join('\n')} rows={hClues.length}
              color={clues.horizontal.length !== hClues.length ? "failure" : "gray"}
              className="peer min-h-64 w-full p-2 resize-none rounded-r-none border-r-0" onChange={() => onChange()}/>
      </div>
      <p className="font-semibold">מאונך:</p>
      <div className="group rtl grid grid-cols-[2.4rem,1fr]">
      <Textarea readOnly defaultValue={vClues.join('.\n')+ "."} rows={vClues.length}
              color={clues.vertical.length !== vClues.length ? "failure" : "gray"}
              className="min-h-64 w-full p-2 resize-none rounded-l-none border-l-0 pointer-events-none group-focus-within:border-cyan-500"/>
      <Textarea readOnly={readOnly} ref={vRef} defaultValue={clues.vertical.join('\n')} rows={vClues.length}
              color={clues.vertical.length !== vClues.length ? "failure" : "gray"}
              className="peer min-h-64 w-full p-2 resize-none rounded-r-none border-r-0" onChange={() => onChange()}/>
      </div>
      {readOnly ? <></> : <Button onClick={saveClues} disabled={loading || cluesLoading}>{(loading || cluesLoading) ? <span className="h-4 w-4"><Loader /></span> : "שנשמור את ההגדרות?"}</Button>}
  </div>) : <Loader/>;
};

export { ClueBoxes };