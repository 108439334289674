import React, { PropsWithChildren } from "react";

const FullHeightText = ({
  children,
  scale = 1,
  maxRem = 1.5,
}: PropsWithChildren<{ scale?: number; maxRem?: number }>) => {
  const fontSize = `min(calc((1vw + 1vh + 1vmin) * ${scale.toString()}), ${maxRem.toString()}rem)`;
  console.log(fontSize);
  return (
    <div
      className="flex justify-center items-center h-full w-full text-center leading-none"
      style={{ fontSize }}
    >
      {children}
    </div>
  );
};

export { FullHeightText };
