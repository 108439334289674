import { Button, Drawer, Tabs, Textarea, TextInput } from "flowbite-react";
import { parse } from 'node-html-parser';
import React, { useEffect } from 'react';
import { BiMessageAltDots } from "react-icons/bi";
import { CgPassword } from "react-icons/cg";
import { GrVirtualMachine } from "react-icons/gr";
import { PiApproximateEqualsBold } from "react-icons/pi";
import { VscSortPrecedence } from "react-icons/vsc";
import { CrosswordComponentProps } from "../types/app";
import mapSeriesAsync from "../utils/map-series";
import Loader from "./loader";

// function CwUserRow({ username, position, close }: { username: string, position: Position, close: () => void }) {
//     return <div className="grid items-center gap-2 grid-cols-[max-content,1fr,max-content]">
//         <Button size="xs" className="aspect-1 items-center" theme={{ size: { sm: "px-1.5 text-sm" } }}
//                 onClick={() => {
//                     setFocus(position);
//                     close();
//                 }}>
//             <TfiTarget/>
//         </Button>
//         <Label htmlFor="accept" className="text-right text-md font-normal"
//                style={{ direction: "rtl", color: seedColor(username) }}>
//             {username}
//         </Label>
//         <FaUserGraduate/>
//     </div>;
// }
function Scrambler() {
    const [letters, setLetters] = React.useState<string>("");
    const [template, setTemplate] = React.useState<string>("");
    const [results, setResults] = React.useState<string[]>([]);

    // Function to generate permutations
    const generatePermutations = (input: string): string[] => {
        if (input.length <= 1) return [input];
        const permutations: string[] = [];
        for (let i = 0; i < input.length; i++) {
            const char = input[i];
            const remainingChars = input.slice(0, i) + input.slice(i + 1);
            const subPermutations = generatePermutations(remainingChars);
            subPermutations.forEach((perm) => {
                permutations.push(char + perm);
            });
        }
        return permutations;
    };

    // Function to filter permutations based on template
    const filterPermutations = (permutations: string[], template: string) => {
        const templateRegex = new RegExp(`^${template.replace(/_/g, '.')}$`);
        return permutations.filter((perm) => templateRegex.test(perm));
    };

    // Function to handle scramble
    const handleScramble = () => {
        setResults([]);
        const letterPermutations = generatePermutations(letters);
        const matchingWords = template.length > 0 ? filterPermutations(letterPermutations, template) : letterPermutations;
        // @ts-ignore
        setResults([...new Set(matchingWords)]); // Remove duplicates
    };

    useEffect(handleScramble, [letters, template]);

    return (
        <div className="grid grid-rows-fr gap-2 rtl">
            <p className="text-center">לערבל לך אותיות? אולי זה יעזור</p>
            <div className="grid grid-flow-col gap-2 px-0.5">
                <TextInput placeholder="אותיות" onChange={e => setLetters(e.target.value.trim())}
                           icon={BiMessageAltDots}/>
                <TextInput type="text" placeholder="מבנה (_ עבור תו חסר)"
                           onChange={e => setTemplate(e.target.value.trim())} icon={CgPassword}/>
            </div>

            {results?.[0]?.length > 0 ? (
                <>
                    <p>זה יוצא {results.length} אפשרויות:</p>
                    <Textarea readOnly className="border leading-none overflow-y-scroll" rows={12}
                              value={results.join(', ')}/>
                </>
            ) : <p>נראה שאין התאמות</p>}

        </div>

    );
}

export async function define(term: string | null) {
    if (!term) {
        return [];
    }

    term = term.trim();

    // const cacheKey = `def-${term.toLowerCase()}`;
    //
    // const cachedResponse = cache.get(cacheKey);
    // if (cachedResponse) {
    //     const { body, timestamp }: AutocompleteCache = JSON.parse(cachedResponse);
    //     const elapsed = Date.now() - timestamp;
    //     if (elapsed <= CACHE_DURATION_IN_MS) {
    //         return body;
    //     }
    // }

    console.log(`fetch: ${term}`);
    const response = await fetch(`https://www.morfix.co.il/en/${term}`);
    const body = await response.text();
    console.log(body);
    const parsed = parseDefintion(body);

    // cache.set(cacheKey, JSON.stringify({ timestamp: Date.now(), body: parsed }));

    return parsed;
}

function parseDefintion(html: string) {
    const root = parse(html);
    const translations = root.querySelectorAll('.normal_translation_div');
    if (!translations) return [];
    return translations.flatMap(((t) => t.text.replaceAll("to ", "").replace(/\(.*\)/, "").split(/[;,\r\n]+/).map(w => w.trim()).filter(w => w.length)));
}

function WordExpander() {
    const [word, setWord] = React.useState<string>("");
    const [results, setResults] = React.useState<string[][]>([]);
    const [loading, setloading] = React.useState<boolean>(false);

    // Function to handle scramble
    const searchWord = async () => {
        setloading(true);
        setResults([]);
        const res = await define(word);
        const returns = await mapSeriesAsync(res, define);
        // @ts-ignore
        setResults([...new Set(returns)]);
        setloading(false);
    };


    return (
        <div className="grid grid-rows-fr gap-2 rtl">
            <p className="text-center">לנתח מלה? אולי זה יעזור</p>
            <div className="grid grid-flow-col gap-2 px-0.5">
                <TextInput placeholder="אותיות" onChange={e => setWord(e.target.value.trim())}
                           icon={BiMessageAltDots}/>
                <Button onClick={searchWord}>שננסה ללמוד משהו?</Button>
            </div>

            {loading ? <div className="min-h-12"><Loader /></div> : results?.[0]?.length > 0 ? (
                <>
                    <p>יש לי {results.map(arr => arr.length).reduce((cur, agg)=> cur+agg, 0)} מושגים קשורים:</p>
                    <Textarea readOnly className="border overflow-y-scroll" rows={12}
                              value={results.map(line => line.join(", ").trim()).join('\n')}/>
                </>
            ) : <p>נראה שאין התאמות</p>}

        </div>

    );
}


function CrosswordToolsDrawer({ store }: CrosswordComponentProps) {
    const toolsDrawerOpen = store.use.toolsDrawerOpen();

    return <Drawer position="top" className="grid grid-rows-[max-content,1fr]" open={toolsDrawerOpen}
                   onClose={() => store.set.toolsDrawerOpen(false)}>
        <Drawer.Header titleIcon={GrVirtualMachine}></Drawer.Header>
        <Drawer.Items>
            <Tabs aria-label="Default tabs" style="default"
                  theme={{
                      tablist: {
                          base: "flex text-center rtl",
                          tabitem: { icon: "ml-1.5 h-4 w-4", styles: { default: { base: "py-2 px-3" } } }
                      }
                  }}>
                <Tabs.Item active title="מערבל" icon={VscSortPrecedence}>
                    <Scrambler/>
                </Tabs.Item>
                <Tabs.Item active title="נרדפות" icon={PiApproximateEqualsBold}>
                    <WordExpander/>
                </Tabs.Item>

            </Tabs>
        </Drawer.Items>
    </Drawer>;
}

export default CrosswordToolsDrawer;
