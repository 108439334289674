export enum Direction {
    Up = "up",
    Down = "down",
    Left = "left",
    Right = "right",
}

export enum Separator {
    Left = "left",
    Bottom = "bottom",
}

export enum IndexTypes {
    Vertical = "vertical",
    Horizontal = "horizontal",
    Both = "both",
}