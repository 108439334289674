// ./src/firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase, onDisconnect, onValue, push, ref, remove, serverTimestamp, set } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAJzYfYXJomrQnz415Bin92qVgfXLkWOWk",
  authDomain: "project-5818804455382223554.firebaseapp.com",
  databaseURL: "https://project-5818804455382223554.firebaseio.com",
  projectId: "project-5818804455382223554",
  storageBucket: "project-5818804455382223554.appspot.com",
  messagingSenderId: "126340794817",
  appId: "1:126340794817:web:0ed4048b8520ede34fc740"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, ref, set, onValue, push, onDisconnect, serverTimestamp, remove };
