import { DatabaseReference } from "@firebase/database";
import { database, ref } from "./firebase";

// Paths:
const cwList = () => `crossWords`;
const cw = (id: string) => `${cwList()}/${id}`;
const meta = (id: string) => `${cw(id)}/meta`;
const image = (id: string) => `/images/${id}/image`;
const imagePath = (id: string) => `${meta(id)}/imagePath`;
const clues = (id: string) => `/clues/${id}`;
const cluesHorizontal = (id: string) => `${clues(id)}/horizontal`;
const cluesVertical = (id: string) => `${clues(id)}/vertical`;

const data = (id: string) => `${cw(id)}/data`;
const cell = (id: string, rowIdx: number, cellIdx: number) => `${data(id)}/${rowIdx}/${cellIdx}`;
const cellBlack = (id: string, rowIdx: number, cellIdx: number) => `${cell(id, rowIdx, cellIdx)}/black`;
const cellIndex = (id: string, rowIdx: number, cellIdx: number) => `${cell(id, rowIdx, cellIdx)}/index`;


const connected = () => `.info/connected`;
const myConnections = (username: string) => `users/${username}/connections`;
const lastOnline = (username: string) => `users/${username}/lastOnline`;
const positions = (id: string) => `positions/${id}`;
const userPosition = (id: string, username: string) => `${positions(id)}/${username}`;

// Refs:
export const cwListRef = (): DatabaseReference => ref(database, cwList());
export const metaRef = (id: string): DatabaseReference => ref(database, meta(id));
export const imageRef = (id: string): DatabaseReference => ref(database, image(id));
export const imagePathRef = (id: string): DatabaseReference => ref(database, imagePath(id));
export const cluesRef = (id: string): DatabaseReference => ref(database, clues(id));
export const cluesHorizontalRef = (id: string): DatabaseReference => ref(database, cluesHorizontal(id));
export const cluesVerticalRef = (id: string): DatabaseReference => ref(database, cluesVertical(id));

export const dataRef = (id: string): DatabaseReference => ref(database, data(id));
export const cellRef = (id: string, rowIdx: number, cellIdx: number): DatabaseReference => ref(database, cell(id, rowIdx, cellIdx));
export const cellBlackRef = (id: string, rowIdx: number, cellIdx: number): DatabaseReference => ref(database, cellBlack(id, rowIdx, cellIdx));
export const cellIndexRef = (id: string, rowIdx: number, cellIdx: number): DatabaseReference => ref(database, cellIndex(id, rowIdx, cellIdx));

export const connectedRef = () => ref(database, connected());
export const positionsRef = (id: string): DatabaseReference => ref(database, positions(id));
export const userPositionRef = (id: string, username: string): DatabaseReference => ref(database, userPosition(id, username));
export const myConnectionsRef = (username: string): DatabaseReference => ref(database, myConnections(username));
export const lastOnlineRef = (username: string): DatabaseReference => ref(database, lastOnline(username));
