import React from 'react';

const Loader = ({ text = '' }: { text?: string }) => {
    return (
        <div className="flex justify-center items-center w-full h-full">
            <div className="flex justify-center items-center relative max-w-32 max-h-32" style={{
                width: '100%',
                height: '100%',
                background: 'url(/loader.svg) no-repeat center',
                backgroundSize: 'contain'
            }}>
                <span>{text ?? ""}</span>
            </div>
        </div>
    );
};

export default Loader;
