import { Button } from "flowbite-react";
import React from "react";
import { BiEdit } from "react-icons/bi";
import { FaUserTag } from "react-icons/fa";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import { GoGear } from "react-icons/go";
import { GrVirtualMachine } from "react-icons/gr";
import { IconType } from "react-icons/lib/iconBase";
import { MdImageSearch } from "react-icons/md";
import { TbColumns, TbKeyboard, TbKeyboardOff } from "react-icons/tb";
import { TiHome } from "react-icons/ti";
import { CrosswordComponentProps } from "../types/app";
import { Cell } from "../types/crossword";
import { Direction } from "../types/enums";
import { seek, setFocus } from "../utils/cell-utils";

function CwSideButton({ onClick, Icon, alt = false }: { onClick: () => void; Icon: IconType; alt?: boolean }) {
    return (
        <Button size={('ontouchstart' in window) ? "sm" : "md"} className="aspect-1 items-center" theme={{ size: { sm: "px-1.5 text-sm", md: "px-2 text-xl" } }}
                color={alt ? "gray" : undefined}
                onClick={onClick}>
            <Icon/>
        </Button>
    );
}

function CrosswordSidebar(props: CrosswordComponentProps) {
    const { data, store, id } = props;
    const disableKeyboard = store.use.disableKeyboard();
    const { rowIdx, cellIdx } = store.use.focusPosition();
    const seeker = (direction: Direction) => {
        if (!focus) return;
        seek(data, direction, rowIdx, cellIdx, {
            stopFn: () => false,
            seekFn: (cell: Cell) => Boolean(cell.index),
        });
    };

    return (
        <div className="grid grid-rows-[max-content,1fr,max-content] ">
            <div className="grid auto-rows-max gap-0.5 items-start">
                <a href="/"><CwSideButton Icon={TiHome} onClick={() => {
                }}/></a>
                <CwSideButton Icon={TbColumns} onClick={() => store.set.cluesDrawerOpen(true)}/>
                <CwSideButton Icon={MdImageSearch} onClick={() => store.set.imageDrawerOpen(true)}/>
                <a href={`/#/crossword/${id}/learn`}><CwSideButton Icon={BiEdit} onClick={() => {
                }}/></a>
                <CwSideButton Icon={GoGear} onClick={() => store.set.settingsDrawerOpen(true)}/>
                <CwSideButton Icon={FaUserTag} onClick={() => store.set.usersDrawerOpen(true)}/>
            </div>
            <div className="spacer"/>
            <div className="grid auto-rows-max gap-0.5 items-start">
                {('ontouchstart' in window) ? <CwSideButton Icon={disableKeyboard ? TbKeyboard : TbKeyboardOff} alt={!disableKeyboard}
                              onClick={() => {
                                  store.set.disableKeyboard(!disableKeyboard);
                                  setTimeout(() => setFocus(store.get.focusPosition()));
                              }}/> : <></>}
                <CwSideButton Icon={GrVirtualMachine} onClick={() => store.set.toolsDrawerOpen(true)}/>
                <CwSideButton Icon={FaAnglesRight} onClick={() => seeker(Direction.Right)}/>
                <CwSideButton Icon={FaAnglesLeft} onClick={() => seeker(Direction.Left)}/>
            </div>
        </div>
    );
}

export default CrosswordSidebar;
