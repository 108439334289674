import clsx from "clsx";
import React, { CSSProperties } from "react";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { CrosswordStore } from "../state/crossword-store";
import { Cell } from "../types/crossword";
import { Direction, Separator } from "../types/enums";
import { seedColor } from "../utils/seed-color";
import { FullHeightText } from "./full-height-text";

type CrosswordCellProps = {
    cell: Cell;
    rowIdx: number;
    cellIdx: number;
    seekFn: (direction: Direction) => void;
    nextFn: () => void;
    isLastRow: boolean;
    isLastColumn: boolean;
    hasBlackLeft: boolean;
    hasBlackBelow: boolean;
    setValueFn: (values: Cell) => void;
    store: CrosswordStore;
};

function CrosswordCell({
                           cell,
                           rowIdx,
                           cellIdx,
                           seekFn,
                           nextFn,
                           isLastRow,
                           isLastColumn,
                           hasBlackLeft,
                           hasBlackBelow,
                           setValueFn,
                           store,
                       }: CrosswordCellProps) {
    const users = store.use.usersForCell(rowIdx, cellIdx);
    const username = store.use.username();
    const showUserColors = store.use.showUserColors();
    const showOthers = store.use.showOthers();
    const disableKeyboard = store.use.disableKeyboard();

    const onFocus = () => {
        void store.set.focusPosition({ rowIdx, cellIdx });
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        if (key === "Backspace" || key === "Delete") return setValueFn({ v: "" });
        if (key === "ArrowUp") return seekFn(Direction.Up);
        if (key === "ArrowDown") return seekFn(Direction.Down);
        if (key === "ArrowLeft") return seekFn(Direction.Left);
        if (key === "ArrowRight") return seekFn(Direction.Right);
        if (key === "Tab") {
            e.preventDefault();
            return seekFn(e.shiftKey ? Direction.Right : Direction.Left);
        }
    };

    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        let key = (e.nativeEvent as unknown as { data: string }).data;

        const qwertyToHebrew: { [key: string]: string } = {
            a: "ש",
            b: "נ",
            c: "ב",
            d: "ג",
            e: "ק",
            f: "כ",
            g: "ע",
            h: "י",
            i: "ן",
            j: "ח",
            k: "ל",
            l: "מ",
            m: "צ",
            n: "ת",
            o: "ם",
            p: "פ",
            r: "ר",
            s: "ד",
            t: "א",
            u: "ו",
            v: "ה",
            x: "ס",
            y: "ט",
            z: "ז",
            ";": "ף",
            "[": "ץ",
            "]": "ך",
            ",": "ת",
            ".": "ץ",
        };

        if (key in qwertyToHebrew) key = qwertyToHebrew[key];

        if (key?.match(/[אבגדהוזחטיכלמנסעפצקרשתףךץםן]/)) {
            const v = key
                .replace("ך", "כ")
                .replace("ם", "מ")
                .replace("ן", "נ")
                .replace("ף", "פ")
                .replace("ץ", "צ");

            if (v !== cell.v) {
                setValueFn({ v, updatedBy: store.get.username() });
                setTimeout(nextFn);
            }
        }
        if (key === "N") {
            setValueFn({ note: !cell.note });
        }
    };

    const handleRightClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (cell.v?.length) {
            if (!cell.black) return setValueFn({ note: !cell.note });
        } else if (store.get.blackEdit()) {
            return setValueFn({ black: !cell.black });
        }

        if (cell.black) return;
        const canSepBelow = !(isLastRow || hasBlackBelow) && cell.separator !== Separator.Bottom;
        const canSepLeft = !(isLastColumn || hasBlackLeft) && cell.separator !== Separator.Left;
        const hasSep = cell.separator === Separator.Left || cell.separator === Separator.Bottom;

        if (!hasSep) {
          if (canSepLeft)
            return setValueFn({ black: false, separator: Separator.Left });
          if (canSepBelow)
            return setValueFn({ black: false, separator: Separator.Bottom });
        } else if (cell.separator === Separator.Left) {
          if (canSepBelow)
            return setValueFn({ black: false, separator: Separator.Bottom });
        }

        return setValueFn({ black: false, separator: null });
    };

    const cellStyle: CSSProperties = {
        borderLeftStyle: cell.separator === "left" ? "dotted" : "solid",
        borderBottomStyle: cell.separator === "bottom" ? "dotted" : "solid",
    };

    const extraClasses = clsx(
        rowIdx === 0 && "border-t",
        cellIdx === 0 && "border-r",
        cell.black && "bg-black",
        cell.separator === "left" && "border-l-2",
    cell.separator === "bottom" && "border-b-2"
    );

    return (
        <div onContextMenu={handleRightClick}
    className={clsx("aspect-1 border-l border-b border-black grid items-center justify-center focus-within:bg-blue-300 ring-0", extraClasses)
      } style={cellStyle} >
            {!cell.black && (
                <input
                    inputMode={disableKeyboard ? "none" : "text"}
                    id={`cell-${rowIdx}-${cellIdx}`}
                    type="text"
                    value=""
                    className="row-start-1 col-start-1 aspect-square p-0 m-0 w-full h-full text-center outline-none bg-transparent caret-transparent selection:bg-transparent align-text-top border-none border-0 focus:ring-0"
                    onKeyDown={handleKeyPress}
                    onInput={handleInput}
                    onFocus={onFocus}
                />
            )}
      {showOthers && (
        <div
                className="row-start-1 col-start-1 flex flex-col content-start p-[2%] gap-[2%] flex-wrap justify-start pointer-events-none bg-transparent"
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundSize: "contain",
                }}
            >
          {users
            .filter((user) => user !== username)
            .slice(0, 5)
            .map((user, i) => (
                    <RiCheckboxBlankCircleFill
                        key={i}
                        size="14%"
                        className="transition-all duration-500 ease-in-out"
                        style={{
                            color: seedColor(user),
                            opacity: 0,
                  animation: users.includes(user)
                    ? "fadeIn 1.5s forwards"
                    : "fadeOut 1s forwards",
                }}
              />
                ))}
        </div>
      )}
            <div
                className={clsx(
          "row-start-1 col-start-1 grid justify-center items-center pointer-events-none bg-transparent aspect-square overflow-hidden bg-contain h-full w-full leading-none -mt-0.5 md:font-semibold",
          cell.note &&
            "font-['Shuneet'] text-slate-400 font-semibold md:font-bold"
                )}
        style={
          !cell.black && !cell.note && cell.updatedBy && showUserColors
            ? { color: seedColor(cell.updatedBy) }
            : {}
        }
      >
        <FullHeightText scale={1.3}>{cell.v ?? ""}</FullHeightText>
            </div>
            {cell?.index?.number && (
        <div className="row-start-1 col-start-1 grid justify-end items-start pointer-events-none bg-transparent aspect-square leading-none overflow-hidden bg-contain h-full w-full">
          <p className="p-0 m-0 -mt-0.5 pointer-events-none leading-none text-[70%]">
            <FullHeightText scale={0.65} maxRem={0.9}>
              {cell?.index?.number}
            </FullHeightText>
          </p>
                </div>
            )}
        </div>
    );
}

export default CrosswordCell;
