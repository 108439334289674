import { Drawer } from "flowbite-react";
import React from 'react';
import { useObjectVal } from "react-firebase-hooks/database";
import { MdImageSearch } from "react-icons/md";
import { imagePathRef, imageRef } from "../db/refs";
import { CrosswordComponentProps } from "../types/app";
import Loader from "./loader";

function CrosswordImageDrawer({ id, meta, store }: CrosswordComponentProps) {
    const [imageData/*, loading, error*/] = useObjectVal<string>(imageRef(id));
    const [imagePath/*, loading, error*/] = useObjectVal<string>(imagePathRef(id));
    const imageDrawerOpen = store.use.imageDrawerOpen();

    return <Drawer position="top" className="grid grid-rows-[max-content,1fr] max-h-dvh" open={imageDrawerOpen}
                   onClose={() => store.set.imageDrawerOpen(false)}>
        <Drawer.Header titleIcon={MdImageSearch} title={`${meta.title} | ${meta.author}`} />
        <Drawer.Items className="overflow-y-scroll">
        {imageData ? <img src={imageData || imagePath} className="" alt="Crossword"/> :
            <Loader/>}
        </Drawer.Items>
    </Drawer>;

}

export default CrosswordImageDrawer;
