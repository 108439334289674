import clsx from "clsx";
import { Button, Table, TextInput } from "flowbite-react";
import { orderBy } from 'lodash';
import React, { useEffect, useState } from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import { HiArrowCircleDown, HiArrowCircleUp } from "react-icons/hi";
import { TbListSearch } from "react-icons/tb";
import { cwListRef } from "../db/refs";
import { Cells, Meta } from "../types/crossword";
import { NestedPaths } from "../types/utils";
import { fromNow } from "../utils/date";
import { percentSolved } from "../utils/stats";
import { changeUsername } from "../utils/username";
import { PageTitle } from "../components/page-title";

type ExtendedMeta = Meta & {
    id: string,
    completed: number
}

const Sorter = ({ name, path, sortedBy, sortedDesc, onClick }: {
    name: string,
    path: NestedPaths<ExtendedMeta>,
    sortedBy: string,
    sortedDesc: boolean,
    onClick: (path: string) => void
}) => {
    const Icon = sortedDesc ? HiArrowCircleDown : HiArrowCircleUp;
    return <div className="grid grid-cols-[max-content,1fr] items-center justify-start gap-0.5 text-[120%]"
                onClick={() => onClick(path ?? 'updatedAt')}><p>{name}</p><Icon
        className={clsx(sortedBy !== path && "opacity-0")}/></div>
        ;
};

const Home = () => {
    const [crosswords, loading] = useObjectVal<Record<string, { data: Cells, meta: Meta }>>(cwListRef());
    const [filteredList, setFilteredList] = useState<ExtendedMeta[]>([]);
    const [username, setUsername] = useState(() => localStorage.getItem('username') || 'Unknown');
    const [sortCol, setSortCol] = useState("updatedAt");
    const [sortDesc, setSortDesc] = useState(true);
    const [filterString, setFilterString] = useState("");

    function sort(sortPath: string) {
        if (sortCol !== sortPath) {
            setSortCol(sortPath);
        } else {
            setSortDesc(!sortDesc);
        }
    }

    useEffect(() => {
        if (!crosswords) return;
        const filtered: ExtendedMeta[] = Object.entries(crosswords as Record<string, {
            data: Cells,
            meta: Meta
        }>).map(([id, { meta, data }]): ExtendedMeta => {
            const completed = percentSolved(data);
            return { id, completed, ...meta, createdAt: meta.createdAt };
        }).filter(cw => [cw.id, cw.author, cw.title].join(' ').includes(filterString));
        const sorted = orderBy(filtered, sortCol, sortDesc ? 'desc' : 'asc');
        setFilteredList(sorted);
    }, [crosswords, sortCol, sortDesc, filterString]);

    const theme = {
        "root": {
            "base": "w-full text-left text-sm text-gray-500 dark:text-gray-400",
            "shadow": "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white dark:bg-black",
            "wrapper": "relative"
        },
        "body": {
            "base": "group/body",
            "cell": {
                "base": "px-1 py-1 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg"
            }
        },
        "head": {
            "base": "group/head text-xs uppercase text-gray-700 dark:text-gray-400",
            "cell": {
                "base": "bg-gray-50 px-1 py-1 group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg dark:bg-gray-700"
            }
        },
        "row": {
            "base": "group/row border-b",
            "hovered": "hover:bg-gray-50 dark:hover:bg-gray-600",
            "striped": "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
        }
    };

    return (
      <>
        <PageTitle title={`רשימת תשבצים`} />
        <div className="container mx-auto px-1 py-3 grid gap-2">
          <div
            className="grid grid-flow-col gap-2 items-center"
            style={{ direction: "rtl" }}
          >
            <h1 className="text-xl font-bold text-center">רשימת תשבצים</h1>
            <a href="/#/new">
              <Button size="xs" className="bg-blue-500 text-white w-full">
                תשבץ חדש!
              </Button>
            </a>
            <Button
              size="xs"
              className="bg-yellow-500 text-white"
              onClick={() => setUsername(changeUsername(username))}
            >
              לא קוראים לי {username} 🧐
            </Button>
          </div>
          {loading ? (
            "Loading..."
          ) : (
            <Table
              theme={theme}
              style={{ direction: "rtl" }}
              className="max-w-2xl text-right"
            >
              <Table.Head>
                <Table.HeadCell colSpan={2}>
                  <div className="grid grid-flow-col">
                    <Sorter
                      name="כותרת"
                      path="title"
                      sortedBy={sortCol}
                      sortedDesc={sortDesc}
                      onClick={sort}
                    />
                    <Sorter
                      name="מחבר"
                      path="author"
                      sortedBy={sortCol}
                      sortedDesc={sortDesc}
                      onClick={sort}
                    />
                    <Sorter
                      name="אחוזים"
                      path="completed"
                      sortedBy={sortCol}
                      sortedDesc={sortDesc}
                      onClick={sort}
                    />
                    <Sorter
                      name="התעדכן"
                      path="updatedAt"
                      sortedBy={sortCol}
                      sortedDesc={sortDesc}
                      onClick={sort}
                    />
                    <Sorter
                      name="נוצר"
                      path="createdAt"
                      sortedBy={sortCol}
                      sortedDesc={sortDesc}
                      onClick={sort}
                    />
                    <Sorter
                      name="מי נגע"
                      path="updatedBy"
                      sortedBy={sortCol}
                      sortedDesc={sortDesc}
                      onClick={sort}
                    />
                  </div>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body style={{ direction: "rtl" }}>
                <Table.Row key="search" style={{ direction: "rtl" }}>
                  <Table.Cell colSpan={2}>
                    <TextInput
                      icon={TbListSearch}
                      sizing="sm"
                      onChange={(e) => setFilterString(e.target.value)}
                      placeholder="הרשימה הזו ארוכה מדי, שנסנן אותה?"
                    />
                  </Table.Cell>
                </Table.Row>
                {filteredList.map(
                  ({
                    id,
                    title,
                    author,
                    createdAt,
                    updatedAt,
                    updatedBy,
                    completed,
                  }) => (
                    <Table.Row key={id} style={{ direction: "rtl" }}>
                      <Table.Cell>
                        <p className="font-semibold text-cyan-600">
                          <a href={`/#/crossword/${id}`}>{title}</a>
                        </p>
                        <p>{author}</p>
                        <p>({completed.toFixed(1)}%)</p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>נוצר {fromNow(createdAt)}</p>

                        <p>נערך {fromNow(updatedAt)}</p>
                        <p>ע&quot;י {updatedBy}</p>
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          )}
        </div>
      </>
    );
};
export default Home;