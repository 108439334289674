import { createStore } from 'zustand-x';
import { Position } from "../types/crossword";

export type CrosswordStateValues = {
    focusPosition: Position
    cluesDrawerOpen: boolean
    imageDrawerOpen: boolean
    usersDrawerOpen: boolean
    toolsDrawerOpen: boolean
    settingsDrawerOpen: boolean
    cellModalPosition: Position | null
    cellUsers: string[][][]
    showUserColors: boolean
    showOthers: boolean
    lineThrough: boolean
    blackEdit: boolean
    disableKeyboard: boolean,
    username: string
};

const defaultInitialState: CrosswordStateValues = {
    focusPosition: { rowIdx: 0, cellIdx: 0 },
    cluesDrawerOpen: false,
    imageDrawerOpen: false,
    usersDrawerOpen: false,
    toolsDrawerOpen: false,
    settingsDrawerOpen: false,
    cellModalPosition: null,
    cellUsers: [],
    showUserColors: true,
    showOthers: true,
    lineThrough: false,
    blackEdit: false,
    disableKeyboard: false,
    username: ""
};

export type CrosswordStoreProps = Partial<CrosswordStateValues>;

export function createCrosswordStore(props: CrosswordStoreProps) {
    let username: string = localStorage.getItem('username') ?? "";

    while (username.length < 3) {
        username = prompt("אז, איך קוראים לנו?") ?? "";
        localStorage.setItem('username', username!);
    }

    const initialState: CrosswordStateValues = {
        ...defaultInitialState,
        ...props,
        ...{ username }
    };

    return createStore("crossword")({ ...initialState })
        .extendSelectors((state, get/*, api*/) => ({
            usersForCell: (rowIdx, cellIdx) => get.cellUsers()?.[rowIdx]?.[cellIdx] ?? [],
        }));
}

export type CrosswordStore = ReturnType<typeof createCrosswordStore>;
