import { Button, ButtonGroup, Modal } from "flowbite-react";
import React from 'react';
import { CrosswordComponentProps } from "../types/app";
import { Separator } from "../types/enums";

function CellModal({ data, store }: CrosswordComponentProps) {
    const cellModalPosition = store.use.cellModalPosition();
    const rowIdx = cellModalPosition?.rowIdx ?? 0;
    const cellIdx = cellModalPosition?.cellIdx ?? 0;
    const cell = data[rowIdx][cellIdx];
    const isBlack = cell?.black ?? false;
    const sepLeft = cell?.separator === Separator.Left;
    const sepBottom = cell?.separator === Separator.Bottom;
    const isNormal = !isBlack && !sepLeft && !sepBottom;

    // const canSetLeft = cellIdx + 1 < data[0].length;
    // const canSetBottom = rowIdx + 1 < data.length;

    const theme = { size: { md: "p-2 text-sm" } };

    function setNormal() {
        store.set.cellModalPosition(null);
    }

    return <Modal show={Boolean(cellModalPosition)} size="md" onClose={() => store.set.cellModalPosition(null)} popup>
        <Modal.Header/>
        <Modal.Body>
            <div className="text-center">
                <div className="flex justify-center gap-4">
                    <ButtonGroup>
                        <Button color={isNormal ? undefined : "gray"} onClick={setNormal} theme={theme}>
                            <div className="border border-slate-800 aspect-1 w-6 border-2"></div>
                        </Button>
                        <Button color={isBlack ? undefined : "gray"} onClick={setNormal} theme={theme}>
                            <div className="border border-slate-800 aspect-1 w-6 bg-slate-600 border-2"></div>
                        </Button>
                        <Button color={sepLeft ? undefined : "gray"} onClick={setNormal} theme={theme}>
                            <div className="border border-slate-800 aspect-1 w-6 border-2" style={{borderLeftStyle: "dotted"}}></div>
                        </Button>
                        <Button color={sepBottom ? undefined : "gray"} onClick={setNormal} theme={theme}>
                            <div className="border border-slate-900 aspect-1 w-6 border-2" style={{ borderBottomStyle: "dotted" }}></div>
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </Modal.Body>
    </Modal>;

}

export default CellModal;
