import React, { useEffect } from "react";
import { serverTimestamp, set } from "../db/firebase";
import { cellIndexRef, cellRef, metaRef } from "../db/refs";
import { CrosswordComponentProps } from "../types/app";
import { Cell, CellIndex, Cells, Meta } from "../types/crossword";
import { Direction, IndexTypes } from "../types/enums";
import { getIndex, next, seek } from "../utils/cell-utils";
import CrosswordCell from "./crossword-cell";

function CrosswordGrid({ id, data, meta, store }: CrosswordComponentProps) {

    useEffect(() => {
        const reIndex = (data: Cells) => {
            let count = 1;
            let hIndex = -1;
            let vIndex = -1;
            data.map((row, rowIdx) =>
                row
                    .slice()
                    .reverse()
                    .map((_cell, cellIdx) => {
                        const type = getIndex(data, rowIdx, cellIdx);
                        let tempH = null;
                        let tempV = null;
                        switch (type) {
                            case IndexTypes.Horizontal:
                                hIndex++;
                                tempH = hIndex;
                                break;
                            case IndexTypes.Vertical:
                                vIndex++;
                                tempV = vIndex;
                                break;
                            case IndexTypes.Both:
                                hIndex++;
                                vIndex++;
                                tempH = hIndex;
                                tempV = vIndex;
                        }

                        void set(cellIndexRef(id, rowIdx, cellIdx), type ? {
                            type,
                            number: count++,
                            hIndex: tempH,
                            vIndex: tempV
                        } as CellIndex : null);
                    }),
            );
        };

        if (data?.length) reIndex(data);
    }, [data]); // Dependency on data state

    const updateValue = (values: Cell, rowIdx: number, cellIdx: number) => {
        const cellData: Cell | undefined = data?.[rowIdx]?.[cellIdx];
        const user = store.get.username();

        const metaUpdate: Meta = {
            ...meta,
            updatedBy: user,
            solvers: { ...meta?.solvers ?? {}, [user]: true },
            // @ts-expect-error this generates a number
            updatedAt: serverTimestamp()
        };

        if (cellData) {
            void set(metaRef(id), metaUpdate);
            void set(cellRef(id, rowIdx, cellIdx), { ...cellData, ...values });
        }
    };

    if (!Array.isArray(data)) {
        return <div>BAD :)</div>;
    }

    return (
        <div className="grid" style={{ gridTemplateColumns: `repeat(${data?.[0]?.length ?? 11}, 1fr)` }}>
            {data.map((row, rowIdx) =>
                row
                    .slice()
                    .reverse()
                    .map((cell, cellIdx) => {
                        const revIndex = row.length - 1 - cellIdx;
                        return (
                            <CrosswordCell
                                key={revIndex}
                                cell={cell}
                                cellIdx={revIndex}
                                rowIdx={rowIdx}
                                isLastRow={rowIdx === data.length - 1}
                                isLastColumn={revIndex === data[rowIdx].length - 1}
                                hasBlackLeft={data[rowIdx]?.[revIndex + 1]?.black === true}
                                hasBlackBelow={data[rowIdx + 1]?.[revIndex]?.black === true}
                                nextFn={() => next(data, rowIdx, revIndex)}
                                seekFn={(direction: Direction) => seek(data, direction, rowIdx, revIndex)}
                                setValueFn={(values: Cell) => updateValue(values, rowIdx, revIndex)}
                                store={store}
                            />
                        );
                    }),
            )}
        </div>
    );
}

export default CrosswordGrid;
