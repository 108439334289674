import { Cells } from "../types/crossword";

export const percentSolved = (data: Cells): number => {
    if (!data) return 0;

    let solvedCells = 0,
        totalCells = 0;
    data.forEach(row => {
        row.forEach(cell => {
            if (!cell.black) {
                totalCells++;
                if ((cell.v?.length ?? 0) > 0 && !cell.note) {
                    solvedCells++;
                }
            }
        });
    });

    return solvedCells / totalCells * 100;
};

