import { Cell, Cells, Position } from "../types/crossword";
import { Direction, IndexTypes } from "../types/enums";

export const hasValue = (data: Cells, rowIdx: number, cellIdx: number): boolean => {
    return data?.[rowIdx]?.[cellIdx] && !data?.[rowIdx]?.[cellIdx].black;
};

export const getIndex = (data: Cells, rowIdx: number, cellIdx: number): IndexTypes | null => {
    if (data?.[rowIdx]?.[cellIdx]?.black) return null;
    const hasHorizontalIndex = !hasValue(data, rowIdx, cellIdx - 1) && hasValue(data, rowIdx, cellIdx + 1);
    const hasVerticalIndex = !hasValue(data, rowIdx - 1, cellIdx) && hasValue(data, rowIdx + 1, cellIdx);

    if (hasHorizontalIndex && hasVerticalIndex) return IndexTypes.Both;
    if (hasHorizontalIndex) return IndexTypes.Horizontal;
    if (hasVerticalIndex) return IndexTypes.Vertical;
    return null;
};

export const next = (data: Cells, rowIdx: number, cellIdx: number) => {

    // Define boundaries and neighboring cell statuses
    const isLeftMost = cellIdx + 1 >= data?.[0]?.length;
    const isBottom = rowIdx + 1 >= data.length;

    const upCell = data[rowIdx - 1]?.[cellIdx];
    const downCell = data[rowIdx + 1]?.[cellIdx];

    const rightCell = data[rowIdx]?.[cellIdx - 1];
    const leftCell = data[rowIdx]?.[cellIdx + 1];


    // Define context-aware navigation
    const preferVertical = (upCell?.v || downCell?.v) && !(leftCell?.v || rightCell?.v);
    const preferHorizontal = (leftCell?.v || rightCell?.v) && !(upCell?.v || downCell?.v);

    const functions: SeekFns = {
        seekFn(cell: Cell): boolean {
            return !cell?.black;
        },
        stopFn(cell: Cell): boolean {
            return (cell?.v?.length ?? 0) > 0;
        },
    };

    if (preferVertical && !isBottom && downCell && !downCell.black) {
        return seek(data, Direction.Down, rowIdx, cellIdx, functions);
    } else if (preferHorizontal && !isLeftMost && leftCell && !leftCell.black) {
        return seek(data, Direction.Left, rowIdx, cellIdx, functions);
    }

    // Check for fallback conditions
    if (!isLeftMost && leftCell && !leftCell.black) {
        return seek(data, Direction.Left, rowIdx, cellIdx, functions);
    } else if (!isBottom && downCell && !downCell.black) {
        return seek(data, Direction.Down, rowIdx, cellIdx, functions);
    }

    // No viable options left or right, attempt other directions
    if (isLeftMost || (leftCell && leftCell.black)) {
        if (!isBottom) {
            return seek(data, Direction.Down, rowIdx, cellIdx, functions);
        }
    }
    if (isBottom || (downCell && downCell.black)) {
        if (!isLeftMost) {
            return seek(data, Direction.Left, rowIdx, cellIdx, functions);
        }
    }
    return false;
};

export const setFocus = ({ rowIdx, cellIdx }: Position) => {
    const newFocusId = `cell-${rowIdx}-${cellIdx}`;
    const element = document.getElementById(newFocusId);
    if (element) {
        element.focus(); // Focus the new cell
        return true;
    }
    return false;
};

export const findFocus = (): Position | null => {
    const activeElement = document.activeElement;
    if (activeElement && activeElement.id.startsWith('cell-')) {
        const idParts = activeElement.id.split('-');
        if (idParts.length === 3) {
            const rowIdx = parseInt(idParts[1], 10);
            const cellIdx = parseInt(idParts[2], 10);
            return { rowIdx, cellIdx };
        }
    }
    return null; // Return null if no focused element matches the expected pattern
};

type SeekFns = {
    seekFn?: (cell: Cell, target: Position) => boolean
    stopFn?: (cell: Cell, target: Position) => boolean
    callback?: (position: Position) => void
}

export const seek = (data: Cells, direction: Direction, rowIdx: number, cellIdx: number, fns: SeekFns = {}, checkMe: boolean = false): {
    target: Position,
    cell: Cell
} | null => {
    const seekFn = fns.seekFn ?? ((cell) => !cell?.black);
    const stopFn = fns.stopFn ?? (() => false);
    const callback = fns.callback ?? setFocus;

    const target: Position = { rowIdx, cellIdx };
    let cell = data[rowIdx]?.[cellIdx];

    if (checkMe) {
        cell = data[target.rowIdx]?.[target.cellIdx];
        // Check if the new cell meets criteria:
        if (cell && seekFn(cell, target)) {
            callback(target);
            return { target, cell };
        }
        // Halt on Stop fn:
        if (stopFn(cell, target)) return null;
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
        switch (direction) {
            case "up":
                target.rowIdx--;
                if (target.rowIdx < 0) {
                    target.rowIdx = data.length - 1;  // Wrap to the last row if it goes above the first
                }
                break;
            case "down":
                target.rowIdx++;
                if (target.rowIdx >= data.length) {
                    target.rowIdx = 0;  // Wrap to the first row if it goes below the last
                }
                break;
            case "left":
                target.cellIdx++;
                if (target.cellIdx >= data[target.rowIdx].length) {
                    target.rowIdx++;
                    target.cellIdx = 0;  // Move to the next row if it goes beyond the last column
                    if (target.rowIdx >= data.length) {
                        target.rowIdx = 0;  // Wrap to the first row if necessary
                    }
                }
                break;
            case "right":
                target.cellIdx--;
                if (target.cellIdx < 0) {
                    if (target.rowIdx === 0) {
                        // Move to the last cell of the last row if we are in the first row
                        target.rowIdx = data.length - 1;
                        target.cellIdx = data[target.rowIdx].length - 1;
                    } else {
                        target.rowIdx--;
                        target.cellIdx = data[target.rowIdx].length - 1;  // Move to the previous row if it goes before the first column
                    }
                }
                break;
        }

        cell = data[target.rowIdx]?.[target.cellIdx];
        // Check if the new cell meets criteria:
        if (cell && seekFn(cell, target)) break;
        // Halt on Stop fn:
        if (stopFn(cell, target)) return null;
        // Prevent infinite loop if all cells are black or undefined
        if (target.rowIdx === rowIdx && target.cellIdx === cellIdx) return null;
    }

    callback(target);
    return { target, cell };
};